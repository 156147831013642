.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    white-space: pre-wrap;
  }
  .modal button {
    outline: none;
    cursor: pointer;
    border: 0;
  }

  .modal > section {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #ffffff;
    animation: modal-show 0.3s;
    overflow: hidden;
    }

  .modal > section > main {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 15px auto; 
  }
  
  .modal > section > header {
    position: relative;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: var(--green);
  }

  .modal > input::placeholder {
    color: var(--gray2);
  }

  .modal > section > footer {
    margin: 0px auto 50px;
    text-align: right;
    display: flex;
    justify-content: center;
  }

  .modal.openModal {
    display: flex;
    align-items: center;
    animation: modal-bg-show 0.3s;
  }
  @keyframes modal-show {
    from {
      opacity: 0;
      margin-top: -50px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  @keyframes modal-bg-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }