:root {
  --green: #2B787D;
  --blue: #005686;
  --white: #FBFBFB;
  --gray0: #F2F2F2;
  --gray1: #BABABA;
  --gray2: #818181;
  --dark: #2F333E;
  --black: #0B0B0B;

  --hb-font: 'SF_HambakSnow';
  --sm-font: "Simonetta", cursive;
  --pre-font: 'Pretendard-Regular';
}

